<template>
  <!-- 比赛成绩详情 -->
  <div class="IndividualCompetition" id="printRoot">
    <div class="detaBox">
      <div class="rowBox">
        <div class="rightBox">比赛项目：</div>
        <div class="leftBox">{{ competitionData.name }}</div>
      </div>
      <div class="rowBox">
        <div class="rightBox">比赛类型：</div>
        <div class="leftBox">{{ competitionData.type }}</div>
      </div>
      <div class="rowBox">
        <div class="rightBox">总人数：</div>
        <div class="leftBox">{{ data1.comPlayerCount }}</div>
      </div>
      <div class="rowBox">
        <div class="rightBox">比赛时间：</div>
        <div class="leftBox">{{ competitionData.startTime }}</div>
      </div>
      <!-- <div class="rowBox">
        <div class="rightBox">比赛发数：</div>
        <div class="leftBox">{{ competitionData.name }}</div>
      </div> -->
    </div>
    <div class="tableContent">
      <div class="groupBox" v-if="groupNameList && groupNameList.length > 0">
        <div
          :class="index == isactive ? 'active' : ''"
          @click="handelchange(index, item)"
          v-for="(item, index) in groupNameList"
          :key="index"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="groupBox groupBox1" v-else>暂无数据</div>
      <div class="searchBox">
        <el-input
          placeholder="请输入选手姓名"
          v-model="groupNameData.playerName"
          clearable
        ></el-input>
        <!-- </div> -->
        <!-- <div class="btn"> -->
        <el-button class="seaBtn" type="primary" size="small" @click="search"
          >查询</el-button
        >
        <!-- <el-button type="primary" size="small" v-print="{ id: 'printRoot' }"
          >打印</el-button
        > -->
        <!-- </div> -->
      </div>
      <el-table :data="tableData" border size="small">
        <el-table-column prop="ranking" label="排名" width="80">
        </el-table-column>
        <el-table-column prop="playerName" label="选手姓名" width="120">
        </el-table-column>
        <!-- <el-table-column prop="project" label="比赛项目"> </el-table-column> -->
        <el-table-column label="场地">
          <template slot-scope="scope">
            <span>{{ scope.row.siteCity }} — {{ scope.row.siteName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="company" label="单位"> </el-table-column>
        <el-table-column prop="shootCount" label="发数" width="120">
        </el-table-column>
        <el-table-column prop="allScore" label="总环" width="120">
        </el-table-column>
        <el-table-column class-name="czBox" label="操作" width="150">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="details(scope.row)"
              >查看成绩</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { getSelectCompetitionPlayer, getSelectscheduleList } from '@/api/scoreQuery/index.js';
export default {
  name: 'IndividualCompetition',
  components: {
  },
  props: {},
  data() {
    return {
      groupNameList: [],
      competitionId: parseInt(sessionStorage.getItem('competitionId')),
      isactive: 0,
      groupNameData: {
        playerName: '',
        scheduleId: null
      },
      competitionData: {}, // 比赛详情
      data1: {},
      tableData: [], // 选手列表
    }
  },
  computed: {},
  watch: {},
  created() {
    this.getSelectscheduleList();
  },
  methods: {
    // 获取赛程阶段
    async getSelectscheduleList() {
      const res = await getSelectscheduleList({ competitionId: this.competitionId });
      // console.log(res);
      if (res.status === 200) {
        if (res.data) {
          res.data.forEach(item => {
            if (item.scheduleName === '1') {
              item.name = '淘汰赛';
              this.groupNameList.push(item);
            }
            if (item.scheduleName === '2') {
              item.name = '资格赛';
              this.groupNameList.push(item);
            }
            if (item.scheduleName === '3') {
              item.name = '8进6决赛';
              this.groupNameList.push(item);
            }
            if (item.scheduleName === '4') {
              item.name = '6进4决赛';
              this.groupNameList.push(item);
            }
            if (item.scheduleName === '5') {
              item.name = '4进2决赛';
              this.groupNameList.push(item);
            }
            if (item.scheduleName === '6') {
              item.name = '总决赛';
              this.groupNameList.push(item);
            }
          });
          // console.log(this.groupNameList);
          this.groupNameData.scheduleId = this.groupNameList[0].scheduleId;
          sessionStorage.setItem('scheduleId', this.groupNameList[0].scheduleId);
          this.getSelectCompetitionPlayer();
        }
      }
    },
    // 根据赛程id查看所有选手
    async getSelectCompetitionPlayer() {
      const res = await getSelectCompetitionPlayer(this.groupNameData);
      // console.log(res);
      if (res.status === 200) {
        this.data1 = res.data;
        this.competitionData = res.data.competitionManagement;
        this.tableData = res.data.playerVoList;
      }
    },
    // 分组事件
    handelchange(index, item) {
      // console.log(item);
      this.groupNameData.scheduleId = item.scheduleId;
      sessionStorage.setItem('scheduleId', item.scheduleId);
      // console.log(this.groupNameData.playerName);
      this.isactive = index;
      this.getSelectCompetitionPlayer();
    },

    // ----------------------------------------------
    // 查看详情
    details(val) {
      // console.log(val);
      // console.log(this.tableData.length);
      sessionStorage.setItem('playerId', val.playerId);
      this.$router.push('/home/scoreQuery/details/personalDetails');
    },
    // 查询
    search() {
      this.getSelectCompetitionPlayer();
    },
  }
}
</script>

<style lang="scss" scoped>
.IndividualCompetition {
  padding: 20px;
  .detaBox {
    .rowBox {
      display: flex;
      margin-bottom: 30px;
      .rightBox {
        width: 90px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #303030;
        line-height: 20px;
        text-align: right;
      }
      .leftBox {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #7d7d7d;
        line-height: 20px;
      }
    }
  }
  .tableContent {
    margin-top: 20px;
    .groupBox {
      height: 30px;
      display: flex;
      float: left;
      // margin-top: 20px;
      div {
        cursor: pointer;
        width: 75px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #303030;
        background: #fff;
        margin-right: 50px;
      }
      .active {
        width: 75px;
        height: 30px;
        background: #06206b;
        border-radius: 3px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
    }
    .groupBox1 {
      color: #aaa;
    }
    .searchBox {
      margin-bottom: 15px;
      float: right;

      .el-input {
        width: 180px;
        margin-right: 15px;
        // margin-bottom: 20px;
      }
      .el-button {
        background: #06206b;
      }
      .seaBtn {
        margin-left: 20px;
        margin-right: 20px;
      }
    }

    .el-table--border {
      // border-bottom: 1px solid #ebeef5;
      border-right: 1px solid #ebeef5;
    }
    ::v-deep.el-table__body {
      .el-table__cell {
        padding: 0 !important;
      }
    }
  }
}
@media print {
  body {
    // -webkit-print-color-adjust: exact;
    // -moz-print-color-adjust: exact;
    // -ms-print-color-adjust: exact;
    // print-color-adjust: exact;
    margin: 0 auto;
    #printRoot {
      background-color: #fff;
      padding-top: 350px;
    }
    .IndividualCompetition {
      padding: 200px;
      .detaBox {
        .rowBox {
          display: flex;
          margin-bottom: 300px;
          .rightBox {
            width: 900px;
            height: 200px;
            font-size: 140px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #303030;
            line-height: 200px;
            text-align: right;
          }
          .leftBox {
            height: 200px;
            font-size: 140px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #7d7d7d;
            line-height: 200px;
          }
        }
      }
      .tableContent {
        margin-top: 200px;
        .groupBox {
          height: 0;
        }
        .groupBox1 {
          height: 0;
        }
        .searchBox {
          height: 0;
        }

        .el-table--border {
          // border-bottom: 1px solid #ebeef5;
          border-right: 10px solid #ebeef5;
        }
        ::v-deep table {
          width: 100%;
          tr {
            th,
            td {
              height: 360px;
              .cell {
                height: 350px;
                line-height: 350px;
                padding: 0 40px;
              }
            }
          }
        }
      }
    }
  }
}
@page {
  /* 打印A4大小 */
  size: auto A4 landscape;
  margin: 0 auto;
}
// 兼容火狐
::v-deep .el-table__body-wrapper {
  overflow-y: scroll;
  scrollbar-color: #d7d7d7 transparent;
  scrollbar-width: thin;
}
// 兼容谷歌
// 滚动条的宽度
::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 6px; // 横向滚动条
  height: 6px; // 纵向滚动条 必写
}
// 滚动条的滑块
::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #d7d7d7;
  border-radius: 3px;
}
::v-deep .el-table__body-wrapper::-webkit-scrollbar-track {
  background: #fff !important;
}
</style>
